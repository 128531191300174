import {Navigate, NavLink, useNavigate} from "react-router-dom";

export default function Main() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <p>MAIN PAGEs</p>
      <NavLink to={'/thank-you/'}>thx</NavLink>
      <br/>
      <button onClick={emulateSentMail}>emulate sent mail</button>
    <br/>
      <p className='class_name'>click conversation as class name</p>
     <br/>
      <NavLink id='conversation' to={'/conversation'}>click conversation as id name</NavLink>
    </div>
  );

  function emulateSentMail(e) {
    e.preventDefault();
    console.info('emulateSentMail init');

    navigate("/thank-you/");
  }

}

